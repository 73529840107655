<template>
  <div class="promotionSet">
    <el-form class=""
             :model="data"
             :ref="formNameEdit"
             v-loading="loading"
             label-width="160px"
             @submit.native.prevent>

      <div class="edit-module">
           <div class="edit-header">
          <div class="left title">推广设置</div>
          <div class="content"></div>
        </div>
    
        <el-form-item class="form-item " label-width="80px"
                      label="活动时间"
                      prop="endTime"
                      :rules="formRules.required">
          <el-date-picker v-model="activityTimeArray" 
                          style="width:30%"
                          format="yyyy-MM-dd HH:mm"
                          value-format="yyyy-MM-dd HH:mm"
                          :disabled="disabledTime"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                          @change="changeActivityTime">
          </el-date-picker>
        </el-form-item>

        <div class="edit-header">
          <div class="left title">活动商品</div>
          <el-button class="left"
                     icon="el-icon-plus"
                     type="primary"
                     @click="showSelectGoods" :disabled="disabledEdit">添加商品</el-button>
          <div class="left color-info">已选中{{this.goodsSkuList.length}}条（最多选择{{maxLength}}条）</div>
          <div class="content"></div>
      
        </div>
        <div class="select-goods-list">
          <el-table :data="goodsSkuListFilterAfter"
                    @select="selection"
                    @select-all="selectionAll"
                    :span-method="spanMethod"
                    max-height="500px"
                    empty-text="请至少选择一件活动商品">
         
            <el-table-column prop="goodsCover"
                             label="商品图"
                             width="120">
              <template slot-scope="scope">
                <div class="flex-item">
                  <img :src="scope.row.goodsCover" alt="" class="table-row-image-60"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName"
                             align="center"
                             label="商品名称"
                             width="120"></el-table-column>
                                <el-table-column type="selection"
                             align="center"
                             width="50"></el-table-column>
             <el-table-column prop="goodsSkuName"
                             label="规格"
                             width="100"></el-table-column>                 
            <el-table-column prop="originalPrice"
                             class-name="input-td"
                             label="原售价"
                             width="140">
            </el-table-column>
           
            <el-table-column prop="stockNum"
                             class-name="input-td"
                             label="库存"
                             width="100">
            </el-table-column>
                 <el-table-column prop="extensionStockNum"
                             class-name="input-td"
                             label="推广库存"
                             width="130">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.extensionStockNum'"
                              :rules="formRules.extensionStockNum">
                  <el-input v-model="scope.row.extensionStockNum"
                            type="number"
                            :disabled="disabledEdit"
                            ></el-input>
                </el-form-item>
              </template>       
            </el-table-column>
            <el-table-column prop="extensionFeeProportion"
                             class-name="input-td"
                             label="推广费比例%"
                             width="130">
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.extensionFeeProportion'"
                              :rules="formRules.extensionFeeProportion">
                  <el-input v-model="scope.row.extensionFeeProportion"
                            type="number"
                            @change="editProportion(scope.row)" :disabled="disabledEdit"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="extensionFee"
                             label="推广费"
                             class-name="input-td"
                             width="130"
                             >
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.extensionFee'"
                              :rules="formRules.extensionFee"
                              >
                  <el-input v-model="scope.row.extensionFee"
                            type="number"
                            @change="editextensionFee(scope.row)"
                            :disabled="disabledEdit"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
               <el-table-column prop="extensionPrice"
                             label="推广供货价"
                             class-name="input-td"
                             width="150"
                            >
              <template slot-scope="scope">
                <el-form-item label=""
                              label-width="0px"
                              :prop="'goodsSkuList.' + getGoodsSkuIndex(scope.row) + '.extensionPrice'"
                              >
                  <el-input v-model="scope.row.extensionPrice"
                            type="number"
                            disabled></el-input>
                </el-form-item>
              </template>
            </el-table-column>
              <el-table-column prop="originalPurchasePrice"
                             label="原供货价"
                             class-name="input-td"
                             width="120"
                             >
            </el-table-column>
            <el-table-column  label="操作">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="clickDeleteSelection(scope.row)" :disabled="disabledEdit">删除</el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex-item margin-tb">
            <el-button class="left"
                       type="primary"
                       @click="Setgoods"
                       :disabled="selectThePageSkuArray.length<1">批量设置</el-button>
            <el-button class="left"
                       type="danger"
                       @click="clickDeleteSelectionAll"
                       :disabled="selectThePageSkuArray.length<1">删除选中</el-button>
            <div class="content"></div>
          </div>
        </div>
      </div>
      <div class="" style="height: 200px;"></div>
      <!-- <div class="edit-module" style="margin-bottom: 100px;" v-if="!1">
        <div class="padding-right-map map-left-width text-center">
          <el-button class="edit-module__button" type="primary" @click="submitForm()"><el-icon class="el-icon-edit-outline margin-right-10"/>保存</el-button>
        </div>
      </div> -->
      <div class="fixed-action-right-bottom edit-module__action">
        <el-button type="" @click="goBack"><el-icon class="el-icon-back"/>返回上页</el-button>
        <el-button type="primary" @click="submitForm()"><el-icon class="el-icon-edit-outline"/>保存</el-button>
      </div>

      <select-seconds-skill-goods :visible.sync="dialogVisibleSelectGoods"
                                  :default-select="selectGoodsIdArray"
                                  :start-time="activityTimeArray[0]"
                                  :end-time="activityTimeArray[1]"
                                  @change="selectGoodsChange"></select-seconds-skill-goods>
    
  </el-form>

      <div  class="batchSet">
        <el-dialog
          title="批量设置"
          :visible.sync="dialogVisibleSet"
          width="25%"
          center
          >
          <el-form ref="form" :model="form" label-width="82px">
              <el-form-item label="推广库存">
                <el-input v-model.number="form.setStock" @change="Setinventory"></el-input>
              </el-form-item>
              <el-form-item label="推广费比例">
                <el-input v-model.number="form.setProportion"  @change="Setproportion"></el-input>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSet = false">取 消</el-button>
            <el-button type="primary" @click="confirmSet()">确 定</el-button>
          </span>
       </el-dialog>
     </div>

  
  </div>

</template>

<script>
  import {required} from '../../../assets/js/formRules'
  import {isPostageFree, goodsManufactureTypeArray, supportRefundArray} from '../../../assets/js/status'
  import {parseTime, deepsClone,JSONStringify} from '../../../assets/js/utils'
  import {URL} from '../../../config/url.supplier'
  import {returnBaseDataSecondsKill} from "../../../assets/js/modules/secondsKill/secondsKill";
  import selectSecondsSkillGoods from '../../../components/supplier/promotionSet/selectPromotionGoods.vue'
  import {goBack,isNumber} from "../../../assets/js/utils";
  import {mapState} from 'vuex'

  export default {
    name: "promotionSet",
    components: {
      selectSecondsSkillGoods,
    },
    created() {
      this.pageInit();
    },
    data() {
      let checkProportion =  (rule, value, callback) =>{
        let index = rule.field.match(/[0-9]+/g);
        let row = this.goodsSkuList[index];
        let errorText = this.judgeProportion(row);
        if(errorText) return callback(new Error(errorText));
        callback();
      };
      let checkStockNum =  (rule, value, callback) =>{
        let index = rule.field.match(/[0-9]+/g);
        let row = this.goodsSkuList[index];
        let errorText = this.judgeStockNum(row);
        if(errorText) return callback(new Error(errorText));
        callback();
      };
      let checkextensionFee =  (rule, value, callback) =>{
        let index = rule.field.match(/[0-9]+/g);
        let row = this.goodsSkuList[index];
        let errorText = this.judgeextensionFee(row);
        if(errorText) return callback(new Error(errorText));
        callback();
      };

      return {
        loading: false,
        /* 路由 */
        originPageUrL: '',
        /* 编辑 */
        formRules:Object.assign({
          extensionFeeProportion:[{trigger: 'blur',validator:checkProportion, },],
          extensionStockNum:[{trigger: 'blur',validator:checkStockNum, }],
          extensionFee:[{trigger: 'blur',validator:checkextensionFee, }],
        },{required}),
        formNameEdit: 'formNameEditSecondsKill',
        data: returnBaseDataSecondsKill(),
        activityTimeArray: [],
        /* 已选中商品表格 */
        maxLength:100,
        keywords:'', // 筛选
        matchKey:'goodsSkuId',
        selectThePageSkuArray:[],
        /* 选择商品弹窗 */
        dialogVisibleSelectGoods: false,
        selectGoodsIdArray: [],
        dialogVisibleSet:false,
        form:{
           setStock:'',
           setProportion:'',
        },
        /* 最小扣点 */
        // minPoint:2,
        // 时间配置-不准选当日之前的数据
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < (Date.now() - 86400000);
          },
        }
      }
    },
    methods: {
    
      pageInit() {
        let params = this.$route.query;
        this.originPageUrL = params.originPageUrL || '';
        // if(params.id) this.getSecondsKillDetails(params.id);
      },
  
      //设置
      Setgoods(){
          this.dialogVisibleSet = true
          this.form.setProportion = ''
          this.form.setStock = ''
      },

      //批量设置
      confirmSet(){
        this.dialogVisibleSet = false
        let arry =  this.selectThePageSkuArray.flat(Infinity)
         arry.forEach(item =>{
           if(Array.isArray(item.addNewBasisModelList)){
             item.addNewBasisModelList.forEach(items =>{
                  this.$set(items,"extensionFeeProportion",Number(this.form.setProportion))
                  this.$set(items,"extensionStockNum",parseInt(this.form.setStock))
                  this.editProportion(items)
             })
             console.log("嵌套数组", item.addNewBasisModelList)
           }
          this.$set(item,"extensionFeeProportion",Number(this.form.setProportion))
          this.$set(item,"extensionStockNum",parseInt(this.form.setStock))
          this.editProportion(item)
        })
        console.log("批量设置输入新的值", arry)
        
      },
      
      goBack,
      /** 时间改变 */
      changeActivityTime(arr = []) {
        let startTime = '';
        let endTime = '';
        if (arr.length) {
          startTime = new Date(arr[0]).getTime();
          if (arr.length > 1) endTime = new Date(arr[1]).getTime();
        }
        this.data.startTime = startTime;
        this.data.endTime = endTime;
      },
      setActivityTimeArray(startTime = this.data.startTime,endTime = this.data.endTime) {
        if (startTime) startTime = parseTime(startTime, 'YYYY-MM-DD HH:mm');
        if (endTime) endTime = parseTime(endTime, 'YYYY-MM-DD HH:mm');
        this.activityTimeArray = [startTime, endTime];
      },
      /**
       * 显示 选择商品弹窗
       */
      showSelectGoods() {
        if (!this.activityTimeArray[1]) return this.$message.error('请选择“开始”及“结束”时间');
        this.dialogVisibleSelectGoods = true;
        this.selectGoodsIdArray = deepsClone(this.goodsSkuList);
      },
      hideSelectGoods() {
        this.dialogVisibleSelectGoods = false;
        this.selectGoodsIdArray = [];
      },
      selectGoodsChange(list) {
        // list = deepsClone(list);
        this.data.goodsSkuList = list.sort((res1,res2)=> res1[this.matchKey] - res2[this.matchKey]);
      },
      /**
       * 表单合并
       */
      spanMethod({ row, column, rowIndex, columnIndex }){
        if(columnIndex === 0){
          let goodsIds = this.skuListGoodsIds;
          if(this.repetitiveGoodsList.includes(row.goodsId)){
            let index = goodsIds.indexOf(row.goodsId);
            if(index === rowIndex){
              let num = goodsIds.filter(id=>id === row.goodsId).length;
              return [num,1];
            }else{
              return [0,0];
            }
          }
        }
        return [1,1]
      },
      /**
       * 表单选中、删除
       */
      selection(selection, row){
        this.selectThePageSkuArray = selection;
        let arry =  this.selectThePageSkuArray.flat(Infinity)
         console.log("单个选中",arry)
      },
      selectionAll(selection){
        this.selectThePageSkuArray = selection;
        let arry =  this.selectThePageSkuArray.flat(Infinity)
        console.log("所有选中",arry)
      },
      clickDeleteSelection(row){
        this.$confirm(`是否移除当前sku商品【${row.goodsSkuName}】？`,'确认操作').then(()=>{
          let index = this.goodsSkuListSkuIds.indexOf(row[this.matchKey]);
          if(index>=0) this.goodsSkuList.splice(index,1);
        });
      },
      clickDeleteSelectionAll(){
        let len = this.selectThePageSkuArray.length;
        if (!len) return this.$message.error('没有选中商品skuList');
        this.$confirm(`是否移除当前选中的${len}个sku商品？`,'确认操作').then(()=> {
          let key = this.matchKey;
          let ids = this.selectThePageSkuArray.map(res=>res[key]);
          this.data.goodsSkuList = this.goodsSkuList.filter(res=>!ids.includes(res[key]));
          this.selectThePageSkuArray = [];
        });
      },

      //编辑比例
      editProportion(row){ 
          console.log("编辑比例",row)
          let extensionFeeProportion = row.extensionFeeProportion;
          extensionFeeProportion = parseFloat(row.extensionFeeProportion/100);
          if (!isNumber(extensionFeeProportion)) return false;
           
                let extensionFee = row.originalPrice*extensionFeeProportion
                extensionFee = parseFloat(extensionFee.toFixed(2))
                this.$set(row,'extensionFee', extensionFee);
                this.$refs[this.formNameEdit].validate();
                console.log("计算推广费",row.extensionFee)
            
                let extensionPrice = row.originalPurchasePrice-extensionFee
                extensionPrice = parseFloat( extensionPrice.toFixed(2))
                this.$set(row,'extensionPrice', extensionPrice);
                this.$refs[this.formNameEdit].validate();
                console.log("计算推广费",row.extensionPrice)
           
      },

      //编辑推广费
      editextensionFee(row){
          console.log("编辑推广费",row)
          let extensionFee = row.extensionFee;
          extensionFee = parseFloat(extensionFee) || 0;
          if (!isNumber( extensionFee)) return false;

                let extensionFeeProportion = (extensionFee/row.originalPrice)*100
                extensionFeeProportion = parseFloat(extensionFeeProportion.toFixed(2))
                this.$set(row,'extensionFeeProportion', extensionFeeProportion);
                this.$refs[this.formNameEdit].validate();
                console.log("计算推广比例",row.extensionFeeProportion)
            
                let extensionPrice = row.originalPurchasePrice-extensionFee
                extensionPrice = parseFloat( extensionPrice.toFixed(2))
                this.$set(row,'extensionPrice', extensionPrice);
                this.$refs[this.formNameEdit].validate();
                console.log("计算推广供货",row.extensionPrice)
      },


       getGoodsSkuIndex(row){
        return this.goodsSkuListSkuIds.indexOf(row.goodsSkuId);
      },

      
      //批量设置库存
      Setinventory(setStock){
        if(setStock < 0){
            this.$message({
               message: '请输入大于0数字',
               type: 'warning'
            });
          return  this.form.setStock = 0    
        }
      },


      //批量比例
      Setproportion(setproportion){
        if(setproportion < 0){
            this.$message({
               message: '请输入大于0数字',
               type: 'warning'
            });
          return  this.form.setProportion = 0    
        }else if(setproportion > 100){
            this.$message({
               message: '请输入不能大于100数字',
               type: 'warning'
            });
          return  this.form.setProportion = 100 
        }
      },


       /**
       * 校验推广比例
       */
      judgeProportion(row){
        let text = '推广价比例';
        let extensionFeeProportion = row.extensionFeeProportion;
        if([0,'0'].includes(extensionFeeProportion)) return '';
        if(!extensionFeeProportion) return `${text}为空`;
        extensionFeeProportion = parseFloat(extensionFeeProportion) || 0;
        if(extensionFeeProportion < 0) return `${text} < 0`;
        return '';
      },

       /**
       * 校验推广库存
       */
        judgeStockNum(row){
        let text = '推广库存';
        let extensionStockNum = row.extensionStockNum;
        let stockNum = row.stockNum
        if([0,'0'].includes(extensionStockNum)) return '';
        if(!extensionStockNum) return `${text}为空`;
        extensionStockNum = parseFloat(extensionStockNum) || 0;
        if(extensionStockNum < 0 ) return `${text} < 0`;
        if(extensionStockNum > stockNum ) return `${text}不能>库存`;
        return '';
      },

       /**
       * 校验推广费
       */
        judgeextensionFee(row){
        let text = '推广费';
        let extensionFee = row.extensionFee;
        let originalPrice = row.originalPrice;
        if([0,'0'].includes(extensionFee)) return '';
        if(!extensionFee) return `${text}为空`;
        extensionFee = parseFloat(extensionFee) || 0;
        if(extensionFee < 0 ) return `${text} < 0`;
        if(extensionFee > originalPrice) return `${text}>原价`;
        return '';
      },
 
      /**
       * 校验 整个this.data.goodsSkuList[] 中每条sku的相关数据
       */
      judgeArrayPrice(arr){
        return arr.every(res=>{
          let index = this.getGoodsSkuIndex(res);
          index += 1;
          let errorText = '';
          if(!errorText) errorText = this.judgeSecondsKillEditStockNum(res);
          if(!errorText) errorText = this.judgeSecondsKillEditPrice(res);
          if(!errorText) errorText = this.judgeSecondsKillEditPurchasePrice(res);
          if(!errorText) errorText = this.judgeSecondsKillEditCostPercent(res);
          if(errorText) this.$notify.error({
            title: errorText,
            message: `第【${index}】条 商品sku 的 ${errorText}`
          });
          return !errorText;
        })
      },
      judgeAllPrice(){
        let judge = true;
        if(this.keywords) judge = this.judgeArrayPrice(this.goodsSkuListFilterAfter);
        if(judge) judge = this.judgeArrayPrice(this.goodsSkuList);
        return judge;
      },

      judgeFormBaseData(){
        let judge = true;
     
        if(judge && !this.goodsSkuList.length){
          judge = false;
          this.$notify.error({  title: '活动商品为空', message: `活动商品列表为空`});
        }
        if(judge && this.goodsSkuList.length > this.maxLength){
          judge = false;
          this.$notify.error({  title: `活动商品条数 > ${this.maxLength}条`, message: `活动商品条数 > ${this.maxLength}条`});
        }
        if(judge) this.judgeAllPrice();
        return judge;
      },
      
  
     /**
       * 表单提交及重置
       * @param formName
       */
      submitForm(formName = this.formNameEdit){
        this.$refs[formName].validate((valid) => {
          if (valid) {
              let promotionGoodsList = []
        this.selectThePageSkuArray.forEach( (item)=> {
            promotionGoodsList.push(
              {
              			goodsId: item.goodsId,
              			promotionGoodsSkuList: [
              				{
              					extensionFeeProportion: Number(item.extensionFeeProportion),
              					extensionStockNum: parseInt(item.extensionStockNum),
              					goodsSkuId:item.goodsSkuId,
                        extensionFee:item.extensionFee
              				}
              			]
              		}
           )
       })
  
        let  submitData = {
             endTime: new Date(this.activityTimeArray[1]).getTime().toString(),
             startTime:new Date(this.activityTimeArray[0]).getTime().toString(),
             promotionGoodsList}
        if (promotionGoodsList == '') {
          this.$message({
            message: '请选中商品再提交',
            type: 'warning'
          });
          return false
        }


        this.loading = true;
        return this.axios.post(URL.supplierPromotingGoods.addPromotionAdmin,submitData).then(res => {
          this.loading = false;
          let routeData= this.originPageUrL ? {
            path:this.originPageUrL,
          } : {
            name:'promotionGoods',
          };
          return this.$router.replace(routeData);
          // return Promise.resolve(res);
        }).catch(res => {
          this.loading = false;
          // return Promise.reject(res);
        });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
     
      },
      /**
       * 表单提交及重置
       * 
      //  */
      // submitForm(formName = this.formNameEdit) { // 按钮：提交审核
      //   console.log(this.$refs,formName);
      //   this.$refs[formName].validate((valid) => {
      //     if(!this.judgeFormBaseData()) return false;
      //     if (!valid) {
      //       console.log('error submit!!');
      //       return false;
      //     }
      //     this.save();
      //     // this.$message.success('表单验证通过');
      //   });
      // },
      // resetForm(formName = this.formNameEdit) {
      //   if(typeof formName !== 'string') formName=this.formNameEditStore;
      //   this.$refs[formName].resetFields();
      // },
    },
    
    computed: {
      ...mapState({
        showPoint: state => state.userInfo.showCostAndPurchase,
      }),
      goodsSkuList(){
        return this.data.goodsSkuList || [];
      },
      goodsSkuListFilterAfter(){
        let list = this.goodsSkuList;
        if(!this.keywords) return list;
        let reg = name => {
          return new RegExp(this.keywords,'ig').test(name);
        };
        return list.filter(res => reg(res.goodsName) || reg(res.goodsSkuName))
      },
      goodsSkuListSkuIds(){
        return this.goodsSkuList.map(res=>res[this.matchKey]);
      },
      disabledEdit(){
        return [3,4].includes(this.data.status);
      },
      disabledTime(){
        if(this.disabledEdit){
          return true;
        }
        return ![0,1].includes(this.data.status);
      },
      // 计算已选择商品sku列表的goodsIds
      skuListGoodsIds(){
        return this.goodsSkuListFilterAfter.map(res=>res.goodsId);
      },
      // 计算已选择商品sku列表中存在复数goodsID的列表
      repetitiveGoodsList(){
        let goodsIds = this.skuListGoodsIds;
        let ids = Array.from(new Set(goodsIds)).filter((id,index)=>{
          return goodsIds.filter(res=>res===id).length > 1;
        });
        console.log(ids);
        return ids;
      },
    },
  }
</script>


<style scoped lang="less">
  .promotionSet{
    background: #fff;
    height:100%;

    .form-item {
      .el-form-item__label{
        width:93px !important;
      }
      .el-form-item__content{
        margin-left: 100px !important;
      }
   }

  .input-td .cell{
      overflow: visible !important; 
      padding: 0 !important;
  }

  .batchSet{
       .el-dialog__body{
       .el-form{
        width:70%;
        padding-left: 40px;
       .el-input{
         width:250px;
       }
       .el-form-item__label {
         width:82px !important;
       }
    }
   
   }
  }
   
}

</style>
